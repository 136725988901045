import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

import '../styles/main.css';

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <html lang="en" />

        <link
          href="https://fonts.googleapis.com/css?family=Montserrat:400,400i,700,700i|Noto+Sans+JP:400,700|Press+Start+2P&display=swap&subset=japanese"
          rel="stylesheet"
        ></link>
        {/*
        <link href="https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700&display=swap&subset=japanese" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css?family=Montserrat:400,400i,700,700i&display=swap" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css?family=Press+Start+2P&display=swap" rel="stylesheet">
        */}
      </Helmet>

      <div className="min-h-screen flex flex-col">
        <header className="pt-5">
          <div className="mx-auto container">
            <nav className="flex flex-col md:flex-row justify-between items-center mb-10">
              <Link to="/" className="text-4xl font-montserrat font-bold">
                The Original 151
              </Link>
              <ul className="inline-flex">
                <li className="p-2 mr-2">
                  <Link
                    to="/"
                    className="inline-block border-b-2 border-gray-100 hover:border-gameboy-pink"
                  >
                    Pokedex
                  </Link>
                </li>
                <li className="p-2 ml-2">
                  <Link
                    to="/about"
                    className="inline-block border-b-2 border-gray-100 hover:border-gameboy-pink"
                  >
                    About
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </header>

        <div className="flex-grow mx-auto container">
          <main className="flex-grow">{children}</main>
        </div>

        <footer className="mx-auto mt-8 mb-4 font-press-start-2p text-xs">
          <p>
            Made with{' '}
            <img src="/img/heart.png" className="inline-block -mt-1" alt="" />{' '}
            by{' '}
            <a
              href="https://twitter.com/39digits"
              className="no-underline text-gray-700"
            >
              @39digits
            </a>
          </p>
        </footer>
      </div>
    </>
  );
};

export default Layout;
